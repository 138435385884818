<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      tabindex="-1"
      id="ProductGalleryModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="ProductGallery"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="container" style="height: 85vh">
            <div
              class="row px-3 mt-2 py-3 d-flex justify-content-between align-items-center"
            >
              <div>
                <h4 class="font-weight-bold" style="color: #4d4950">
                  Product Gallery
                </h4>
              </div>
              <div>
                <button
                  @click="closeModal"
                  class="outline-none outline-none border-0 px-2 py-1 text-white"
                  style="
                    cusrsor: pointer;
                    border-radius: 6px;
                    background: #b3afb6;
                  "
                >
                  Cancel
                </button>
                <button
                  v-if="checkGalleryMode"
                  @click="selectImageDone(imageSrc)"
                  class="outline-none outline-none border-0 px-3 py-1 text-white mx-2"
                  :style="
                    imageSrc
                      ? 'background:#4D1B7E'
                      : 'background:#4D1B7Ea6; pointer-events: none;'
                  "
                  style="cusrsor: pointer; border-radius: 6px"
                >
                  Save
                </button>
              </div>
            </div>
            <div class="row px-3 mt-2">
              <div class="col-8">
                <div class="form-group subheading d-flex align-items-center">
                  <input
                    type="text"
                    id="imgInput"
                    v-model="imageLink"
                    placeholder="Place image Link "
                    class="form-control-sm"
                    style="
                      width: 95%;
                      background: transparent;
                      color: #4d4950;
                      border: 1px solid #b3afb6;
                    "
                  />
                  <span
                    v-if="imageLink.length > 0"
                    @click="imageLink = ''"
                    class="ml-2"
                    id="add_product_cancel_button"
                    style="color: red; cursor: pointer"
                  >
                    <i class="fa fa-times float-right"></i>
                  </span>
                </div>
              </div>
              <div class="col-4">
                <button
                  @click="uploadImageLink"
                  class="outline-none outline-none border-0 px-2 py-1 text-white w-100"
                  :class="spinner ? 'py-2' : ''"
                  :style="
                    imageLink.length > 0
                      ? 'background:#4D1B7E'
                      : 'background:#4D1B7Ea6; pointer-events: none;'
                  "
                  style="cusrsor: pointer; border-radius: 6px"
                >
                  <span v-if="!spinner">Add image</span>
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
            <div>
              <div v-if="isLoading">
                <LoaderComp />
              </div>
              <div
                v-else
                style="height: 70vh; overflow-x: hidden; overflow-y: scroll"
              >
                <div class="row p-3" style="">
                  <div class="col-6 mb-2" @dragover.prevent @drop.prevent>
                    <input
                      type="file"
                      accept="image/png,image/gif,image/jpeg,image/svg+xml"
                      style="display: none"
                      ref="fileInput"
                      multiple
                      @change="uploadFile"
                    />
                    <div
                      v-show="!uploadingStatus"
                      @dragenter.prevent="setActive"
                      @dragover.prevent="setActive"
                      @dragleave.prevent="setInactive"
                      @drop="dragFile"
                      class="productUpload d-flex flex-column justify-content-center align-items-center"
                      @click="$refs.fileInput.click()"
                    >
                      <i
                        class="fa fa-cloud-upload fa-4x"
                        aria-hidden="true"
                      ></i>
                      <h4 class="font-weight-bold">
                        {{ dragActive ? "Drop to upload" : "Upload or Drop" }}
                      </h4>
                    </div>
                  </div>
                  <div
                    v-show="fileList.length"
                    class="col-6 mb-2"
                    v-for="(file, fileIndex) in fileList"
                    :key="fileIndex"
                  >
                    <div
                      class="image-div"
                      @click="toggleSelectImage(file.image)"
                    >
                      <LazyImage
                        :class="
                          imageSrc == file.image
                            ? 'selected-image'
                            : 'other-image'
                        "
                        :src="file.image"
                        alt=""
                      />
                      <span
                        v-show="imageSrc == file.image"
                        class="Polaris-Icon_yj27d"
                      >
                        <svg
                          viewBox="0 0 20 20"
                          class="selected-svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fill="white"
                            d="m8.315 13.859-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.436.436 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderComp from "../../customize/component/LoaderComp.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import $ from "jquery";
export default {
  components: {
    LoaderComp,
  },
  props: ["productImages"],
  data() {
    return {
      File: [],
      fileList: [],
      imageSrc: "",
      uploadingStatus: false,
      uploadingPercent: "89%",
      isLoading: false,
      imageLink: "",
      spinner: false,
      selectedImage: "",
      galleryMode: "",
      Token: "",
      isLink: false,
      dragActive: false,
    };
  },
  mounted() {
    //  $(this.$refs.ProductGallery).on("show.bs.modal", this.getImages)
    this.Token = this.GetTokenFromMetaTag;
  },
  methods: {
    OpenImageModal(galleryMode, img) {
      this.galleryMode = galleryMode;
      if (img) {
        this.selectedImage = img;
      }
      this.fileList = this.productImages;
      this.imageSrc = this.selectedImage;
      $("#ProductGalleryModal").modal("show");
    },
    ImageSrc(img) {
      return img.startsWith("upload") ? `/${img}` : img;
    },
    toggleSelectImage(path) {
      if (this.imageSrc == path) this.imageSrc = "";
      else this.imageSrc = path;
    },
    getImages() {
      axios.get("/images/list").then((response) => {
        this.fileList = response.data;
      });
    },
    uploadFile(e) {
      // let  = e.target.files;
      this.uploadOnServer(e.target.files);
    },
    dragFile(e) {
      // this.File = e.dataTransfer.files;
      this.uploadOnServer(e.dataTransfer.files);
    },
    async uploadImageLink() {
      this.spinner = true;

      try {
        this.isLink = true;
        let data = new FormData();
        data.append("product_id", this.getRandomValue);
        data.append("isLink", this.isLink);
        data.append("link", this.imageLink);
        data.append("images", []);

        const response = await axios.post(`/uploadProductGallery`, data);

        if (response.status == 200) {
          this.imageLink = "";
          for (let image in response.data) {
            this.fileList.push(response.data[image]);
          }
          if (
            this.galleryMode == "AddProductMainImage" ||
            this.galleryMode == "AddProductOtherImage" ||
            this.galleryMode == "AddProductAddMore"
          ) {
            this.$emit("ADDUpdateProductImages", this.fileList);
          } else {
            this.$emit("EditUpdateProductImages", this.fileList);
          }
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.spinner = false;
        this.dragActive = false;
      }
    },
    setActive() {
      this.dragActive = true;
    },
    setInactive() {
      this.dragActive = false;
    },
    async uploadOnServer(files) {
      this.isLoading = true;
      try {
        let formData = new FormData();
        formData.append("product_id", this.getRandomValue);
        formData.append("isLink", false);
        formData.append("link", "");
        formData.append("_token", this.Token);
        for (const file of files) {
          if (
            file.type == "image/jpeg" ||
            file.type == "image/png" ||
            file.type == "image/gif" ||
            file.type == "image/svg+xml"
          )
            formData.append("images[]", file);
        }
        const response = await axios.post("/uploadProductGallery", formData);
        if (response.status == 200) {
          for (let image in response.data) {
            this.fileList.push(response.data[image]);
          }
          if (
            this.galleryMode == "AddProductMainImage" ||
            this.galleryMode == "AddProductOtherImage" ||
            this.galleryMode == "AddProductAddMore"
          ) {
            this.$emit("ADDUpdateProductImages", this.fileList);
          } else {
            this.$emit("EditUpdateProductImages", this.fileList);
          }
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isLoading = false;
        this.dragActive = false;
      }
    },

    selectImageDone(imageSrc) {
      const image = this.ImageSrc(imageSrc);
      if (this.galleryMode == "VariantImage") {
        this.$emit("selectedImageCallback", image);
      } else if (this.galleryMode == "AddProductMainImage") {
        this.$emit("changeProductImage", image);
      } else if (this.galleryMode == "AddProductOtherImage") {
        this.$emit("changeProductOtherImage", image);
      } else if (this.galleryMode == "EditProductMainImage") {
        this.$emit("changeEditProductImage", image);
      } else if (this.galleryMode == "EditVariantImages") {
        this.$emit("EditVariantImages", image);
      } else if (this.galleryMode == "EditProductOtherImages") {
        this.$emit("changeEditProductOtherImage", image);
      }

      this.closeModal();
    },
    closeModal() {
      this.selectedImage = "";

      this.fileList = [];
      this.imageSrc = "";
      $("#ProductGalleryModal").modal("hide");
    },
  },
  computed: {
    ...mapGetters(["GetTokenFromMetaTag"]),
    getRandomValue() {
      return Math.floor(Math.random() * (1000 - 11 + 1) + 11);
    },
    checkGalleryMode() {
      if (
        this.galleryMode == "EditAddMore" ||
        this.galleryMode == "AddProductAddMore"
      )
        return false;
      return true;
    },
  },
};
</script>

<style scoped>
.modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}
.productUpload {
  height: 100%;
  text-align: center;
  background: #e0dee280;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}
.productUpload i,
.productUpload h4 {
  color: #6d6b6d;
}
.productUpload:hover {
  background: rgba(77, 27, 126, 0.5);
}
.productUpload:hover i,
.productUpload:hover h4 {
  color: #ffffff;
}
.image-div {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}
.image-div img {
  position: absolute;
}
.other-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.selected-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid #ffb201;
  cursor: pointer;
}
.selected-svg {
  height: 20px;
  width: 20px;
  background: #ffb201;
  border-radius: 100px;
  color: white;
  position: absolute;
  bottom: 6px;
  right: 10px;
}
.percent-text {
  position: absolute;
  font-size: 16px;
  bottom: 12px;
  font-weight: bold;
}
#imgInput:focus {
  outline: none !important;
}
</style>
